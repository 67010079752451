<template>
  <section class="hero is-fullheight">
    <ModalAddSectors
      v-if="activeModalAddSector"
      :active="activeModalAddSector"
      :loading="loading"
      @close-modal="$emit('close-modal')"
      @set-data-create="setDataCreate"
    />
    <div class="hero-body">
      <div class="container">
        <div class="columns is-multiline">
          <div class="column is-7 is_grid">
            <div class="table-container">
              <base-table
                :columns="columnsData"
                :data="sectorsList"
                :loading=loading
              >
                <div slot="status" slot-scope="scope">
                  <p :class="[ scope.row.active ? 'text_green' : 'text_error' ]">
                    {{ scope.row.active ? 'Activo' : 'Inactivo' }}
                  </p>
                </div>
                <div slot="action" slot-scope="scope">
                  <img class="action_img" src="@/assets/icon/edit_icon_pen.png" alt="Editar" @click="showDetailAction(scope.row)">
                </div>
              </base-table>
            </div>
          </div>
          <div class="column is-5">
            <card-sectors
              v-if="showDetail"
              :titleCard="titleCard"
              :modeCreate="modeCreate"
              :dataSector="dataSector"
              :loading="loadingEdit"
              @cancel-action="showDetail = false"
              @set-data-update="setDataUpdate"
            />
          </div>
          <div class="column is-7 pagination">
            <base-pagination
              :page="page"
              :pages="parseInt(pages)"
              :action="paginator"
              :loading="loading"
            />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import moment from 'moment'
import { mapActions } from 'vuex'
const DEFAULT_DISPLAY_SIZE = 50

export default {
  name: 'SectorsMain',
  components: {
    BaseTable: () => import('@/components/UI/BaseTable.vue'),
    BasePagination: () => import('@/components/UI/BasePagination.vue'),
    CardSectors: () => import('@/components/Products/Sectors/CardSectors.vue'),
    ModalAddSectors: () => import('@/components/Products/Sectors/ModalAddSectors.vue')
  },
  data () {
    return {
      loading: false,
      loadingEdit: false,
      page: 1,
      pages: 1,
      modeCreate: false,
      showDetail: false,
      titleCard: 'EDITAR SECTOR',
      dataSector: {},
      columnsData: [
        { id: 'id', header: 'ID' },
        { id: 'name', header: 'Marca' },
        { id: 'created_at', header: 'Fecha de creación', accessor: (row) => moment(row.created_at).format('DD/MMM/YYYY') },
        { id: 'status', name: 'status', header: 'Estatus' },
        { id: 'action', name: 'action', header: 'Acciones', class: 'has-text-centered' }
      ],
      sectorsList: []
    }
  },
  props: {
    activeModalAddSector: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    ...mapActions(['createProductSector', 'getProductSectors', 'updateProductSector']),
    async getProductSectorsList (page) {
      const resp = await this.getProductSectors({ page: page, limit: DEFAULT_DISPLAY_SIZE })
      this.pages = resp.totalPages
      this.sectorsList = resp.sectors
      this.loading = false
    },
    async setDataCreate (data) {
      this.loading = true
      const resp = await this.createProductSector(data)
      if (resp) {
        this.page = 1
        this.$router.push(
          { name: 'sectores-productos', query: { page: this.page } }
        ).catch(() => {})
        this.getProductSectorsList(this.page)
        this.$emit('close-modal')
      }
      this.loading = false
    },
    async setDataUpdate (data) {
      this.loadingEdit = true
      const resp = await this.updateProductSector(data)
      if (resp) {
        this.loading = true
        this.getProductSectorsList(this.page)
        this.showDetail = false
      }
      this.loadingEdit = false
    },
    async showDetailAction (event) {
      this.showDetail = false
      this.loadingEdit = true
      this.dataSector = await event
      this.showDetail = true
      this.loadingEdit = false
    },
    paginator (page) {
      this.showDetail = false
      this.loading = true
      this.page = page
      this.$router.push(
        { name: 'sectores-productos', query: { page: this.page } }
      ).catch(() => {})
      this.getProductSectorsList(this.page)
    }
  },
  beforeMount () {
    this.loading = true
    const { page } = this.$router.history.current.query
    this.page = (page === '' || page === undefined) ? this.page : parseInt(page, 10)
    this.getProductSectorsList(this.page)
  }
}
</script>

<style lang="scss" scoped>
  .action_img {
    width: 15px;
    margin: auto 10px;
    cursor: pointer;
  }
  .pagination {
    /deep/ .pagination-content {
      float: none;
      .pagination-list {
        justify-content: flex-end;
      }
    }
  }
</style>
